@font-face {
  font-family: 'KaushanScript';
  src: local('KaushanScript'), url('./assets/KaushanScript-Regular.ttf') format('truetype');
}

.css-6hp17o-MuiList-root-MuiMenu-list {
 padding: 0 !important;
 
}
.css-1wuxhjj-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 32px !important;
}


.css-9425fu-MuiOutlinedInput-notchedOutline{
  border: none !important;
}
/* .MuiInputBase-root MuiFilledInput-root MuiFilledInput-underline MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-multiline css-t851lb-MuiInputBase-root-MuiFilledInput-root:{
background: transparent !important;
} */

.css-t851lb-MuiInputBase-root-MuiFilledInput-root {
  background: transparent !important;
}